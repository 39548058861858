import React, { useEffect, useState, useContext, useRef } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { Collapse, Col, Row, Input } from 'reactstrap';
import { connect } from 'react-redux';
import moment from 'moment';
import parse from 'html-react-parser';
import anchorme from 'anchorme';
import SearchBox from './Frame/Search/SearchBox';
import EventTimeDetails from '../EventTimeDetails';
import ItemFollowingCards from '../../../UserProfile/Frame/ContributionHistory/Frame/ItemFollowingCards';
import { images } from 'library/common/constants/ImageConstants';
import FormGroup from 'library/common/components/FormGroupComponent';
import { getUserCards } from 'library/common/actions/UserCardsAction';
import { fetchFromStorage } from '../../../../utility';
import { identifiers } from '../../../../library/common/constants/IdentifierConstants';
import axiosInstance from '../../../../main/axios';
import { URLS } from '../../../../library/common/constants/UrlConstants';
import { EventEmitter } from 'library/common/constants/event';
import { SocketContext } from '../../../../main/context/socket';
import PageLoader from 'library/common/components/PageLoader';
import InfiniteScroll from 'react-infinite-scroll-component';

const Auction = props => {
  const params = useParams();
  const location = useLocation();
  var eventId = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;
  const token = fetchFromStorage(identifiers.token);
  const userData = fetchFromStorage(identifiers.userDetail);

  const [loading, setLoading] = useState(false);
  const [itemFollowingCardData, setItemFollowingCardData] = useState([]);
  const [categoryList, setCategoryList] = useState([]);
  const [isabout, setIsAbout] = useState(true);
  const [auctionData, setAuctionData] = useState([]);
  const [selectedCatValue, setSelectedCatValue] = useState('');
  const [sortVal, setSortVal] = useState('PO');
  const [searchVal, setSearchVal] = useState('');
  const [offset, setOffset] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const limit = 12;

  useEffect(() => {
    props.getUserCards();
    fetchCategoryList();
    fetchAuctionDetails(offset, sortVal, selectedCatValue, searchVal);
  }, []);

  const offsetRef = useRef(offset);

  useEffect(() => {
    offsetRef.current = offset; // Update the ref whenever offset changes
  }, [offset]);

  useEffect(() => {
    const handleSocketUpdate = event => {
      const newItem = event?.item;
      if (!newItem || !newItem?.id) return; // Skip if invalid data

      // Update `setItemFollowingCardData` with specific properties (myBidCount, myCurrentCount)
      setItemFollowingCardData(prevData => {
        const updatedData = prevData.map(item => {
          if (item?.id === newItem?.id) {
            // fetchAuctionDetails(offsetRef.current, sortVal, selectedCatValue, searchVal, 'isSocketUpdate');
            // Update only the relevant fields to bidding
            const isCurrentUser = userData?.id === newItem?.userBid?.userId;
            return {
              ...item,
              myCurrentBid: isCurrentUser ? (newItem?.userBid?.myCurrentBid ?? item.myCurrentBid) : item.myCurrentBid,
              myMaxBid: isCurrentUser ? (newItem?.userBid?.myMaxBid ?? item.myMaxBid) : item.myMaxBid,
              currentBid: newItem.currentBid ?? item.currentBid,
              reserveMet: newItem?.reserveMet ?? item.reserveMet,
              winner: {
                ...item.winner,
                ...newItem.winner,
              },
            };
          }
          return item; // Return unchanged item if ID doesn't match
        });
        // Optionally, dispatch the event if needed
        EventEmitter.dispatch('updateBiddingData', updatedData);

        return updatedData;
      });
    };

    EventEmitter.subscribe('socketUpdateBiddingData', handleSocketUpdate);

    return () => {
      EventEmitter.unsubscribe('socketUpdateBiddingData', handleSocketUpdate);
    };
  }, [sortVal, selectedCatValue, searchVal]);

  // Fetch category list for the auction event
  const fetchCategoryList = async () => {
    setLoading(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getCategoryPublic(eventId));
      if (status === 200 || status === 201) {
        setCategoryList(data?.data?.categories);
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
    }
  };

  // Function to get auction details for the event
  const fetchAuctionDetails = async (page, sortBy = 'PO', categoryBy, searchBy = '', isUpdateData) => {
    const previewStatus = location?.state?.previewStatus ?? 0;
    var queryString = `?token=${token?.token ? token?.token : ''}&previewStatus=${previewStatus}&sort=${sortBy}&category=${categoryBy}&search=${searchBy}&offset=${page}&limit=${limit}`;
    setOffset(page);
    if (isUpdateData !== 'isSocketUpdate') setLoading(true);

    try {
      const { status, data } = await axiosInstance.get(URLS.auctionAll(eventId, queryString));
      if (status === 200 || status === 201) {
        setAuctionData(data);

        const newAuctionOptions = data?.data?.eventFeatures[0]?.auctionOptions;

        // Update auction data without duplicates
        if (isUpdateData === 'isSocketUpdate' || isUpdateData === 'infiniteScroll') {
          setItemFollowingCardData(prevData => {
            const updatedData = [...prevData];

            newAuctionOptions.forEach(newItem => {
              const existingIndex = updatedData.findIndex(item => item?.id === newItem?.id); // Match by unique identifier
              if (existingIndex !== -1) {
                // Update existing item
                updatedData[existingIndex] = { ...updatedData[existingIndex], ...newItem };
              } else {
                // Add new item
                updatedData.push(newItem);
              }
            });
            EventEmitter.dispatch('updateBiddingData', updatedData);
            return updatedData;
          });
        } else {
          EventEmitter.dispatch('searchFilterData');
          setItemFollowingCardData(newAuctionOptions);
        }

        setTotalCount(data?.data?.eventFeatures[0]?.auctionOptionsTotalRecords);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };

  // Handle sort by for filtering auction items
  const handleSortByChange = e => {
    setSortVal(e.target.value);
    fetchAuctionDetails(1, e.target.value, selectedCatValue, searchVal);
  };

  // Handle category change
  const handleCategoryChange = value => {
    const selectCat = value !== undefined ? value : '';
    setSelectedCatValue(selectCat);
    fetchAuctionDetails(1, sortVal, selectCat, searchVal);
  };

  // Search auction items based on input value and category selection
  const handleOnSearchChange = value => {
    setSearchVal(value);
    fetchAuctionDetails(1, sortVal, selectedCatValue, value);
  };

  return (
    <Col sm="12" className="event-details-auction">
      {loading && <PageLoader />}
      <Row className="res-org-about ">
        <Col md="8" lg="9">
          <div>
            {auctionData?.data?.eventFeatures?.[0]?.featureName && (
              <div className="d-flex align-items-center justify-content-between">
                <h4
                  className="pt-4 mb-3 cursor-pointer"
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}>
                  {auctionData?.data?.eventFeatures?.[0]?.featureName}
                </h4>
                <img
                  className={!isabout ? 'closed cursor-pointer' : 'cursor-pointer'}
                  onClick={() => {
                    setIsAbout(!isabout);
                  }}
                  src={images?.uppArraow}
                  alt="collapse arrow"
                  width="15px "
                />
              </div>
            )}
            {props?.eventDetails?.eventAbout != null && (
              <Collapse isOpen={isabout}>
                <div>
                  {auctionData?.data?.eventFeatures?.[0]?.featureAbout != null ? (
                    <div className="fs-16 font-Segoe_UI">
                      {parse(
                        anchorme({
                          input: (auctionData?.data?.eventFeatures?.[0]?.featureAbout)
                            .toString()
                            .replace(/\n/g, '<br/>'),
                          options: {
                            attributes: {
                              target: '_blank',
                              class: 'detected',
                            },
                          },
                        }),
                      )}
                    </div>
                  ) : (
                    ''
                  )}
                </div>
              </Collapse>
            )}
          </div>
        </Col>
        <Col md="4" lg="3" className="pt-4 mt-3 mb-3">
          <EventTimeDetails
            img={images.watch}
            title={OKTION_VARIABLE?.OKTION_AUCTION_DATE}
            startDate={moment(auctionData?.data?.eventFeatures?.[0]?.startTime).format('DD/MM/YYYY hh:mm A')}
            endDate={moment(auctionData?.data?.eventFeatures?.[0]?.endTime).format('DD/MM/YYYY hh:mm A')}
            isLocation={false}
          />
        </Col>
      </Row>

      <div className="">
        <h4 className="pt-4 mb-3">{OKTION_VARIABLE?.OKTION_AUCTION_LOT}</h4>
        <Row>
          <Col md="8" sm="12">
            <SearchBox
              handleSelect={handleCategoryChange}
              onSearch={handleOnSearchChange}
              eventDetails={props?.eventDetails}
              categoryList={categoryList}
            />
          </Col>
          <Col sm="12">
            <div className="d-flex align-items-center justify-content-end mt-3 mb-2">
              <p className="sort-by fs-16">{OKTION_VARIABLE?.OKTION_SORT_BY}: &nbsp;</p>
              <FormGroup className="mb-0">
                <Input
                  type="select"
                  name="select"
                  id="exampleSelect"
                  className="cursor-pointer"
                  onChange={handleSortByChange}>
                  <option value="PO">{OKTION_VARIABLE?.OKTION_PUBLISHED_ORDER}</option>
                  <option value="PVLTOH">{OKTION_VARIABLE?.OKTION_PRICE_LOWTO_HIGH}</option>
                  <option value="PVHTOL">{OKTION_VARIABLE?.OKTION_PRICE_HIGHTO_LOW}</option>
                  <option value="BHTOL">{OKTION_VARIABLE?.OKTION_HOTTEST}</option>
                  <option value="FF">{OKTION_VARIABLE?.OKTION_FEATURE_FIRST}</option>
                </Input>
              </FormGroup>
            </div>
          </Col>
          <Col sm="12">
            {itemFollowingCardData && itemFollowingCardData?.length > 0 ? (
              <>
                <InfiniteScroll
                  dataLength={itemFollowingCardData.length}
                  next={() => fetchAuctionDetails(offset + 1, sortVal, selectedCatValue, searchVal, 'infiniteScroll')}
                  hasMore={itemFollowingCardData.length < totalCount}
                  // loader={<h4>Loading...</h4>}
                  // endMessage={<p className="w-100 text-center">No more data.</p>}
                  className="auction_lot_items overflow-x-hidden">
                  <Row>
                    {itemFollowingCardData?.map((item, index) => (
                      <Col key={index} sm="12" md="6" lg="4" className="mb-3 mt-3">
                        <ItemFollowingCards
                          cardItemData={item}
                          isBid={true}
                          categoryList={categoryList}
                          index={index}
                          eventFeatures={props?.eventFeatures}
                          eventDetailsData={props?.eventDetails}
                          userCards={props?.userCards}
                          whitelabel={props?.whitelabel}
                          eventOutfit={props?.eventDetails}
                          isDragable={false}
                        />
                      </Col>
                    ))}
                  </Row>
                </InfiniteScroll>
              </>
            ) : (
              <Col className="mb-2 text-center">
                <h3>{OKTION_VARIABLE?.OKTION_AUCTION_LOTNOT_FOUND}</h3>
              </Col>
            )}
          </Col>
        </Row>
      </div>
    </Col>
  );
};

const mapStateToProps = ({ userCardsReducer }) => {
  return {
    userCards: userCardsReducer.userCards,
  };
};

export default connect(mapStateToProps, { getUserCards })(Auction);
