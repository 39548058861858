import React, { useState, useEffect, useContext } from 'react';
import { Col, Nav, NavItem, NavLink, Row, TabContent, TabPane } from 'reactstrap';
import Auction from './Frames/Auction/Auction';
import About from './Frames/About/About';
import Donations from './Frames/Donation/Donation';
import Raffle from './Frames/Raffle/Raffle';
import Sponsors from './Frames/Sponsors/Sponsors';
import Tickets from './Frames/Tickets/Tickets';
import { URLS } from '../../library/common/constants/UrlConstants';
import { fetchFromStorage, saveToStorage } from '../../utility';
import { identifiers } from '../../library/common/constants/IdentifierConstants';
import PageLoader from '../../library/common/components/PageLoader';
import axiosInstance from '../../main/axios';
import { EventEmitter } from 'library/common/constants/event';
import { jwtDecode } from 'jwt-decode';
import { connect } from 'react-redux';
import { getUserDetails, logout, setAuthentication } from 'library/common/actions/AuthActions';
import { useParams, useLocation } from 'react-router-dom';
import { SocketContext } from '../../main/context/socket';
import 'swiper/css';

const EventTabWrapper = props => {
  const params = useParams();
  const location = useLocation();
  var eventId = params?.id;
  const userSocket = useContext(SocketContext);
  const OKTION_VARIABLE = userSocket?.messages;

  // Event tab list. Do not change id bcz it's used in filter
  const eventsTabList = [
    { id: 1, name: OKTION_VARIABLE?.OKTION_ABOUT },
    { id: 2, name: OKTION_VARIABLE?.OKTION_AUCTION, value: 'auction' },
    { id: 3, name: OKTION_VARIABLE?.OKTION_DONATION, value: 'donation' },
    { id: 4, name: OKTION_VARIABLE?.OKTION_RAFFLE, value: 'raffle' },
    { id: 5, name: OKTION_VARIABLE?.OKTION_TICKET, value: 'ticket' },
    { id: 6, name: OKTION_VARIABLE?.OKTION_SPONSORS },
  ];
  const [activeTab, setActiveTab] = useState('1');
  const [loading, setLoader] = useState(false);
  const [eventFeatures, seteventFeatures] = useState([]);
  const [timeZone, setTimeZone] = useState('');

  const isAuction = location?.state?.isAuction;
  const token = fetchFromStorage(identifiers?.token);
  const searchParams = new URLSearchParams(location?.search);
  const autoLoginToken = searchParams.get('token');
  const fetureType = searchParams.get('type');

  // Handle tabs value change
  const handleTabChange = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
      EventEmitter.dispatch('activeTabData', tab);
    }
  };

  // Utility function to check if a token is a valid JWT
  function isJwt(token) {
    try {
      const decoded = jwtDecode(token);
      return !!decoded;
    } catch (error) {
      return false;
    }
  }

  // Subscribing to WebSocket updates and handling auto-login on component mount
  useEffect(() => {
    isJwt(autoLoginToken) && handleAutoLogin();
  }, []);

  // Fetching the event's time zone and handling loading state when event details are available
  const fetchTimeZone = async () => {
    // setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getTimeZone());
      if (status === 200) {
        setLoader(false);
        var TIMEZONE_ARRAY = data?.data?.timezones;
        var timeZoneOffset = TIMEZONE_ARRAY.find(item => item.id === props?.eventDetails?.timeZoneId);
        setTimeZone(timeZoneOffset?.utcOffset);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  useEffect(() => {
    if (token && props?.eventDetails) {
      fetchTimeZone();
    }
  }, [props?.eventDetails?.timeZoneId]);

  // Fetching event details and setting the active tab based on the feature type
  useEffect(() => {
    getEventDetailsById();
    var defaultTabValue =
      props.featureName === 'auction'
        ? '2'
        : props.featureName === 'donation'
          ? '3'
          : props.featureName === 'raffle'
            ? '4'
            : props.featureName === 'ticket'
              ? '6'
              : '1';
    setActiveTab(isAuction ? '2' : fetureType ? '3' : defaultTabValue);
    EventEmitter.dispatch('setFeaturesData', defaultTabValue);
  }, []);

  // Function to get event details based on the event ID and update the event features
  const getEventDetailsById = async () => {
    const previewStatus = location?.state?.previewStatus;
    var prevStatus = previewStatus !== undefined ? previewStatus : 0;
    setLoader(true);
    try {
      const { status, data } = await axiosInstance.get(URLS.getFeatureByEventId(eventId, prevStatus, token?.token));
      setLoader(false);
      if (status === 200 || status === 304) {
        seteventFeatures(data?.data?.eventFeatures);
        EventEmitter.dispatch('setFeaturesData', data?.data?.eventFeatures);
      }
    } catch (err) {
      setLoader(false);
    }
  };

  // Function to handle user logout and redirect after logging out
  const handleLogout = async () => {
    setLoader(true);
    const authToken = {
      token: autoLoginToken,
    };
    const userDetail = fetchFromStorage(identifiers.userDetail);
    const requestParameters = {
      webAppToken: authToken?.user?.hasOwnProperty('webAppToken') ? authToken?.user?.webAppToken : '',
    };
    try {
      const { status } = await axiosInstance.post(URLS.logoutByUserId(userDetail?.id), requestParameters);
      if (status === 200 || status === 201) {
        props.logout();
        saveToStorage('token', authToken);
        window.location.href = '/event-details/' + eventId + '?type=donation';
      }
    } catch (err) {
      setLoader(false);
    }
  };

  // Function to handle automatic login when a token is present
  const handleAutoLogin = () => {
    const storedToken = fetchFromStorage(identifiers?.token);

    if (storedToken?.token) {
      handleLogout();
    } else {
      const jsonToken = {
        token: autoLoginToken,
      };
      saveToStorage('token', jsonToken);
      props.getUserDetails();
      window.location.href = '/event-details/' + eventId + '?type=donation';
    }
  };

  return (
    <div className="eventDetailsTab">
      <Row>
        <Col>{loading && <PageLoader />}</Col>
      </Row>
      <Nav
        tabs
        className="event-tab border-bottom border-top details-tab-main"
        style={{
          color: props?.eventDetails?.primaryColour ? props?.eventDetails?.primaryColour : '',
        }}>
        <div className="show-on-mobile">
          <div className="tab-container">
            <div className="tabs">
              {eventsTabList?.map((item, i) => {
                var featureType = props?.eventDetails?.featureType;
                var flag = props?.eventDetails && featureType.includes(item?.value);
                var tempFlag = item.hasOwnProperty('value');

                return (
                  <React.Fragment key={i}>
                    {(flag || !tempFlag) && (
                      <li
                        className={`${activeTab === `${item?.id}` && 'active'} tab`}
                        style={{
                          borderBottom: `${
                            activeTab === `${item?.id}` && props?.eventDetails?.primaryColour
                              ? `solid 5px ${props?.eventDetails?.primaryColour}`
                              : ''
                          }`,
                        }}>
                        <a
                          className="nav-link"
                          style={{
                            color: `${
                              activeTab === `${item?.id}` && props?.eventDetails?.primaryColour
                                ? props?.eventDetails?.primaryColour
                                : ''
                            }`,
                          }}
                          onClick={() => {
                            handleTabChange(`${item?.id}`);
                          }}>
                          {item.name}
                        </a>
                      </li>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </div>
        </div>
        {eventsTabList?.map((item, i) => {
          var featureType = props?.eventDetails?.featureType;
          var flag = props?.eventDetails && featureType.includes(item?.value);
          var tempFlag = item.hasOwnProperty('value');
          return (
            <React.Fragment key={i}>
              {(flag || !tempFlag) && (
                <NavItem
                  className={`hide-on-mobile ${activeTab === `${item.id}` && 'active'}`}
                  style={{
                    borderBottom: `${
                      activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                        ? `solid 5px ${props?.eventDetails?.primaryColour}`
                        : ''
                    }`,
                  }}>
                  <NavLink
                    style={{
                      color: `${
                        activeTab === `${item.id}` && props?.eventDetails?.primaryColour
                          ? props?.eventDetails?.primaryColour
                          : ''
                      }`,
                    }}
                    onClick={() => {
                      handleTabChange(`${item.id}`);
                    }}>
                    {item.name}
                  </NavLink>
                </NavItem>
              )}
            </React.Fragment>
          );
        })}
      </Nav>

      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <About eventDetails={props?.eventDetails} whitelabel={props?.whitelabel} timeZone={timeZone} />
        </TabPane>
        <TabPane tabId="2">
          {eventFeatures.some(item => item.type === 'auction') && (
            <Row>
              <Col sm="12">
                {activeTab === '2' && (
                  <Auction
                    eventFeatures={eventFeatures}
                    eventDetails={props?.eventDetails}
                    whitelabel={props?.whitelabel}
                  />
                )}
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="3">
          {eventFeatures.some(item => item.type === 'donation') && (
            <Row>
              <Col sm="12">
                {activeTab === '3' && (
                  <Donations
                    eventFeatures={eventFeatures}
                    eventDetails={props?.eventDetails}
                    whitelabel={props?.whitelabel}
                    timeZone={timeZone}
                  />
                )}
              </Col>
            </Row>
          )}
        </TabPane>
        <TabPane tabId="4">
          {eventFeatures.some(item => item.type === 'raffle') && (
            <>
              {activeTab === '4' && (
                <Raffle
                  eventDetails={props?.eventDetails}
                  eventFeatures={eventFeatures}
                  previewcolor={props.preview_color}
                  label_value={props.white_lable}
                  whitelabel={props?.whitelabel}
                  timeZone={timeZone}
                />
              )}
            </>
          )}
        </TabPane>
        <TabPane tabId="5">
          {eventFeatures.some(item => item.type === 'ticket') && (
            <>
              {activeTab === '5' && (
                <Tickets
                  eventFeatures={eventFeatures}
                  eventDetails={props?.eventDetails}
                  whitelabel={props?.whitelabel}
                  timeZone={timeZone}
                />
              )}
            </>
          )}
        </TabPane>

        <TabPane tabId="6">{activeTab === '6' && <Sponsors eventDetails={props?.eventDetails} />}</TabPane>
      </TabContent>
    </div>
  );
};

const mapStateToProps = ({ authReducer }) => {
  return {
    isLoggedIn: authReducer.isLoggedIn,
  };
};

export default connect(mapStateToProps, { getUserDetails, logout, setAuthentication })(EventTabWrapper);
